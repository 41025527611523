import React, { useEffect, useState } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { FaCheckDouble, FaExclamationTriangle, FaWindowClose } from 'react-icons/fa';
import { Link } from "react-router-dom";

import food from "../images/food-facebook-cover-20.png";
import brandLogo from '../images/qamson.png'

import emailjs from 'emailjs-com';


const Cart = () => {

  const websitePrefix = 'www.qamsonsports.com';
  const userid = localStorage.getItem(`${websitePrefix}-publicid`);
  const email = localStorage.getItem(`${websitePrefix}-publicemail`);
  const address = localStorage.getItem(`${websitePrefix}-publicaddress`);
  const phone = localStorage.getItem(`${websitePrefix}-publicphone`);
  const name = localStorage.getItem(`${websitePrefix}-publicname`);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataOrders, setDataOrders] = useState([]);

  const fetchData = async () => {
    try {

      const response = await fetch(
        `https://qamsonsports.com/database/my-cart.php?userid=${encodeURIComponent(userid)}`
      );
      const json = await response.json();

      console.log(json);

      if (Array.isArray(json)) {
        setData(json);
      } else {
        console.error('Received data is not an array:', json);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {

    }
  };


  const myOrders = async () => {
    try {

      const response = await fetch(
        `https://qamsonsports.com/database/my-orders.php?userid=${encodeURIComponent(userid)}`
      );
      const json = await response.json();

      console.log(json);

      if (Array.isArray(json)) {
        setDataOrders(json);
      } else {
        console.error('Received data is not an array:', json);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {

    }
  };

  const [page, setPage] = useState('');

  useEffect(() => {

    if (localStorage.getItem(`${websitePrefix}-current`) === '1') {
      setPage('/transknit-home')
    }
    else if (localStorage.getItem(`${websitePrefix}-current`) === '0') {
      setPage('/transknit-attire')
    }

    fetchData();
    myOrders();
  }, []);



  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    setTotalSum(data.reduce((accumulator, item) => {
      const productTotal = item.quantity * item.product_price;
      return accumulator + productTotal;
    }, 0));
  }, [data]);



  const decrement = async (id) => {



    try {
      const formData = new FormData();
      formData.append('id', id);


      const response = await fetch('https://qamsonsports.com/database/decrement.php', {
        method: 'POST',
        body: formData,
      });

      const json = await response.json();

      if (json.success === true) {
        // Added successfully
        fetchData();



      } else {
        // Failed to add



      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }


  };



  const increment = async (id) => {



    try {
      const formData = new FormData();
      formData.append('id', id);

      const response = await fetch('https://qamsonsports.com/database/increment.php', {
        method: 'POST',
        body: formData,
      });

      const json = await response.json();

      if (json.success === true) {

        fetchData();

      } else {


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }


  };


  const deleteCartItem = async (id) => {




    try {
      const formData = new FormData();
      formData.append('id', id);

      const response = await fetch('https://qamsonsports.com/database/delete-cart-item.php', {
        method: 'POST',
        body: formData,
      });

      const json = await response.json();

      if (json.success === true) {

        fetchData();

      } else {


      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }


  };



  const [isOpen, setIsOpen] = useState(false);

  function openOverlay(id) {

    setIsOpen(true);
  };

  const closeOverlay = () => {
    setIsOpen(false);
  };




  const [toEmail, setToEmail] = useState('orders@qamsonsports.com');
  const [subject, setSubject] = useState('New Order');
  const [message, setMessage] = useState('Hey There, you got an order!');



  const sendEmail = () => {
    const emailParams = {
      to_email: toEmail,
      subject: subject,
      message: message
    };

    emailjs.send('service_kg9p9ld', 'template_hekzabk', emailParams, 'uSThYDJhrUbrMHRpa')
      .then((result) => {
        console.log(result.text);
        // Add success handling (e.g., show a success message)
      }, (error) => {
        console.log(error.text);
        // Add error handling (e.g., show an error message)
      });
  };




  const [note, setNote] = useState('');
  const [payment, setPayment] = useState('');



  const confirmOrder = async (id) => {
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('note', note);
      formData.append('payment', payment);
  
      const response = await fetch('https://qamsonsports.com/database/confirm-order.php', {
        method: 'POST',
        body: formData,
      });
  
      const json = await response.json();
  
      if (json.success === true) {
        closeOverlay();
        fetchData();
        myOrders();
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaCheckDouble className="mr-2 text-green-600" />
            <span>Order Placed</span>
          </div>,
          {
            className: 'custom-toast-success', // Add a custom CSS class for styling
          }
        );

        // sendEmail();
      } else {
        // Handle different error messages
        let message;
        if (json.message.startsWith('Insufficient amount for product:')) {
          const productTitle = json.message.replace('Insufficient amount for product: ', '');
          // message = `Insufficient quantity for ${productTitle}`;
          message = `Insufficient quantity`;
        } else {
          switch (json.message) {
            case 'Payment details are required':
              message = 'Payment details are required';
              break;
            case 'Product not available':
              message = 'One or more products are not available';
              break;
            case 'Cart is empty':
              message = 'Your cart is empty';
              break;
            default:
              message = 'Error confirming order: ' + json.message;
          }
        }
  
        toast(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FaExclamationTriangle className="mr-2 text-red-600" />
            <span>{message}</span>
          </div>,
          {
            className: 'custom-toast-error', // Add a custom CSS class for styling
          }
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FaExclamationTriangle className="mr-2 text-red-600" />
          <span>Error placing order</span>
        </div>,
        {
          className: 'custom-toast-error', // Add a custom CSS class for styling
        }
      );
    }
  };
  


  return (

    <div className="bg-white"
    >

      <style>{`
    @layer utilities {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  `}
      </style>

      <Header />

      <body className="font-poppins"

      >
        <div class="min-h-screen bg-white"
        // style={{backgroundImage: "url('https://img.freepik.com/premium-photo/two-burgers-with-flying-ingredients-isolated_338799-621.jpg?w=900')",
        // backgroundSize: 'auto 100%',
        // backgroundRepeat: 'no-repeat'}}
        >



          <div className="flex flex-col-reverse lg:flex-row py-16">
            <div class="flex flex-col w-full md:pl-10"
            >

              <div class="relative px-5 mb-6 mt-12 md:mt-6">
                <img src={food} class="w-full relative z-10 rounded-lg" alt="" />
                <div class="border-4 border-yellow-200 absolute top-10 bottom-10 left-10 right-10 z-0"></div>
              </div>


              {/* <h1 class="mb-6 text-center text-lg font-bold text-gray-950 mt-6">Cart Items</h1> */}
              {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                  <div class="rounded-lg w-full px-4">

                    <div class="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
                      <div className="w-2/12 justify-center items-center hidden md:block">

                        <img src={`https://qamsonsports.com${item.image}`} className="h-20 w-20 object-contain" />

                      </div>

                      {/* <img src="https://images.unsplash.com/photo-1587563871167-1ee9c731aefb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1131&q=80" alt="product-image" class="w-full rounded-lg sm:w-40" /> */}
                      <div class="sm:ml-4 sm:flex sm:w-full sm:justify-between items-center">




                        <div className="flex flex-row justify-center items-center">

                          <div className="w-4/12 justify-center items-center pr-4 block md:hidden">

                            <img src={`https://qamsonsports.com${item.image}`} className="h-30 w-30 object-contain" />

                          </div>


                          <div class="mt-5 sm:mt-0 pr-4">



                        

                            <p className="mb-4">
                              {item.quantity_available !== undefined ? (
                                item.quantity_available > 0 ? (
                                  <span>
                                    <div className="border border-sky-500 h-8 w-48 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                                      <div className="flex items-center">
                                        <div className="h-1 w-1 rounded-full bg-sky-500 mr-1" />
                                        <span className="text-xs text-sky-500 font-normal">
                                          QTY Available: {item.quantity_available}
                                        </span>
                                      </div>
                                    </div>

                                  </span>
                                ) : (
                                  <div className="border border-red-500 h-8 w-24 mb-4 md:mb-0 rounded-md flex items-center justify-center">
                                    <div className="flex items-center">
                                      <div className="h-1 w-1 rounded-full bg-red-500 mr-1" />
                                      <span className="text-xs text-red-500 font-normal">
                                        Sold Out
                                      </span>
                                    </div>
                                  </div>

                                )
                              ) : (
                                <div className="mt-3 animate-pulse bg-gray-300 h-6 rounded mb-5"></div>
                              )}
                            </p>



                            <h2 class="text-lg font-bold text-gray-900">
                              {item.product_title}
                            </h2>
                            <p class="mt-1 text-xs text-gray-700">{item.product_description}</p>
                          </div>
                        </div>


                        <div class="mt-4 flex justify-between im sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
                          <div class="flex items-center border-gray-100 justify-end">
                            <button onClick={() => decrement(item.id)} class="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"> - </button>
                            <input class="h-8 w-8 border bg-white text-center text-xs outline-none" type="number" value={item.quantity} min="1" />
                            <button onClick={() => increment(item.id)} class="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"> + </button>
                          </div>
                          <div class="flex items-center space-x-4">
                            <p class="text-sm w-max">{item.product_price * item.quantity} Rs</p>
                            <button onClick={() => deleteCartItem(item.id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 cursor-pointer duration-150 hover:text-red-500">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))


              ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }} className="text-xs md:flex-row flex-col flex px-4 shadow-md  max-w-2xl mx-4 mb-4 rounded-lg">
                  <h2>No items added to cart, add some now.</h2>{' '}<span className="text-sky-500 ml-2" > <Link to={`${page}/products`}>Check out the Products!</Link> </span>
                </div>
              )}


              {/* <h1 class="mb-6 text-center text-lg font-bold text-white mt-4 ">Orders History</h1> */}


             

              <section class="antialiased text-gray-600 pb-6">

             

                <div class="flex flex-col justify-center h-full">

                  <div class="max-w-2xl mx-4 bg-white shadow-md rounded-lg">
                    <header class="px-5 py-4 border-b border-gray-100">
                      <h2 class="font-semibold text-gray-800">Orders</h2>
                      <div className="flex">
            <p className="mb-6 mt-2 text-xs">Have questions about your order status or need assistance with order modifications?
              <span> <a href="mailto:orders@qamsonsports.com" className="text-xs w-fit text-sky-500 justify-center text-center rounded-lg shadowflex items-center underline">
              Contact
            </a></span>
            </p>
           
          </div>
                    </header>
                    <div class="p-3">
                      <div class="overflow-x-auto">
                        <table class="table-auto w-full">
                          <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                            <tr>
                              <th class="p-2 whitespace-nowrap">
                                <div class="font-semibold text-left">Order id</div>
                              </th>
                              <th class="p-2 whitespace-nowrap">
                                <div class="font-semibold text-left">Order</div>
                              </th>
                              <th class="p-2 whitespace-nowrap">
                                <div class="font-semibold text-left">Total</div>
                              </th>
                              <th class="p-2 whitespace-nowrap">
                                <div class="font-semibold text-center">Status</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody class="text-sm divide-y divide-gray-100">


                            {Array.isArray(dataOrders) && dataOrders.map((item, index) => (

                              <tr>
                                <td class="p-2 whitespace-nowrap">
                                  <p>#{item.id}</p>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                  <div className="text-left" dangerouslySetInnerHTML={{ __html: item.meal.replace(/\n/g, '<br />') }}></div>
                                </td>
                                <td class="p-2 whitespace-nowrap">
                                  <div class="text-left font-medium text-gray-800">{item.total} Rs</div>
                                </td>


                                <td className="p-2 whitespace-nowrap">
                                  {item.status === "delivered" && (
                                    <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
                                      <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                      Delivered
                                    </span>
                                  )}
                                  {item.status === "cancelled" && (
                                    <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600">
                                      <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                                      Cancelled
                                    </span>
                                  )}
                                  {item.status === "pending" && (
                                    <span className="inline-flex items-center gap-1 rounded-full bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600">
                                      <span className="h-1.5 w-1.5 rounded-full bg-gray-600"></span>
                                      Pending
                                    </span>
                                  )}
                                  {item.status === "confirmed" && (
                                    <span className="inline-flex items-center gap-1 rounded-full bg-sky-50 px-2 py-1 text-xs font-semibold text-sky-600">
                                      <span className="h-1.5 w-1.5 rounded-full bg-sky-600"></span>
                                      Confirmed
                                    </span>
                                  )}
                                  {item.status === "payment failed" && (
                                    <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600">
                                      <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
                                      Payment failed
                                    </span>
                                  )}
                                </td>
                              </tr>

                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>







            </div>




            <style>
              {`
	.barcode {
		left: 50%;
		box-shadow: 1px 0 0 1px, 5px 0 0 1px, 10px 0 0 1px, 11px 0 0 1px, 15px 0 0 1px, 18px 0 0 1px, 22px 0 0 1px, 23px 0 0 1px, 26px 0 0 1px, 30px 0 0 1px, 35px 0 0 1px, 37px 0 0 1px, 41px 0 0 1px, 44px 0 0 1px, 47px 0 0 1px, 51px 0 0 1px, 56px 0 0 1px, 59px 0 0 1px, 64px 0 0 1px, 68px 0 0 1px, 72px 0 0 1px, 74px 0 0 1px, 77px 0 0 1px, 81px 0 0 1px, 85px 0 0 1px, 88px 0 0 1px, 92px 0 0 1px, 95px 0 0 1px, 96px 0 0 1px, 97px 0 0 1px, 101px 0 0 1px, 105px 0 0 1px, 109px 0 0 1px, 110px 0 0 1px, 113px 0 0 1px, 116px 0 0 1px, 120px 0 0 1px, 123px 0 0 1px, 127px 0 0 1px, 130px 0 0 1px, 131px 0 0 1px, 134px 0 0 1px, 135px 0 0 1px, 138px 0 0 1px, 141px 0 0 1px, 144px 0 0 1px, 147px 0 0 1px, 148px 0 0 1px, 151px 0 0 1px, 155px 0 0 1px, 158px 0 0 1px, 162px 0 0 1px, 165px 0 0 1px, 168px 0 0 1px, 173px 0 0 1px, 176px 0 0 1px, 177px 0 0 1px, 180px 0 0 1px;
		display: inline-block;
		transform: translateX(-90px);
	}
  `}
            </style>
            <div class="flex flex-col  bg-center bg-cover w-full px-4"
            // style={{ backgroundImage: "url(https://images.unsplash.com/photo-1519666336592-e225a99dcd2f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1888&q=80)"}}>
            >
              <div class="absolute bg-transparent pointer-events-none opacity-80 inset-0 z-0"></div>
              <div class="max-w-md w-full mx-auto z-10 bg-sky-500 rounded-3xl shadow-2xl">
                <div class="flex flex-col">
                  <div class="bg-white relative  rounded-3xl p-4 m-4">
                    <div class="flex-none sm:flex">
                      <div class=" relative h-32 w-32   sm:mb-0 mb-3 hidden">
                        <img src="https://tailwindcomponents.com/storage/avatars/njkIbPhyZCftc4g9XbMWwVsa7aGVPajYLRXhEeoo.jpg" alt="aji" class=" w-32 h-32 object-cover rounded-2xl" />
                        <a href="#"
                          class="absolute -right-2 bottom-2   -ml-3  text-white p-1 text-xs bg-green-400 hover:bg-green-500 font-medium tracking-wider rounded-full transition ease-in duration-300">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                            class="h-4 w-4">
                            <path
                              d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z">
                            </path>
                          </svg>
                        </a>
                      </div>
                      <div class="flex-auto justify-evenly">
                        <div class="flex items-center justify-between">
                          <div class="flex items-center  my-1">
                            <span class="mr-3 rounded-full bg-white w-24 h-24">
                              <img src={brandLogo} class="w-24 p-1" />
                            </span>

                          </div>
                          <div class="ml-auto mr-4 text-blue-800">🕛Pending</div>
                        </div>
                        <div class="border-b border-dashed border-b-2 my-5"></div>
                        <div class="flex items-center">
                          <div class="flex flex-col">
                            <div class="flex-auto text-xs text-gray-400 my-1">
                              <span class="mr-1 "></span><span></span>
                            </div>
                            <div class="w-full flex-none text-lg text-blue-800 font-bold leading-none"></div>
                            <div class="text-xs"></div>

                          </div>
                          <div class="flex flex-col mx-auto">
                            <img src={brandLogo} class="w-40 p-1" />

                          </div>
                          <div class="flex flex-col ">
                            <div class="flex-auto text-xs text-gray-400 my-1">
                              <span class="mr-1"></span><span></span>
                            </div>
                            <div class="w-full flex-none text-lg text-blue-800 font-bold leading-none">ISB</div>
                            <div class="text-xs">ISLAMABAD</div>

                          </div>
                        </div>
                        <div class="border-b border-dashed border-b-2 my-5 pt-5">
                          <div class="absolute rounded-full w-5 h-5 bg-sky-500 -mt-2 -left-2"></div>
                          <div class="absolute rounded-full w-5 h-5 bg-sky-500 -mt-2 -right-2"></div>
                        </div>
                        <div class="flex items-center mb-5 p-5 text-sm">
                          {/* <div class="flex flex-col">
									<span class="text-sm">Delivery</span>
									<div class="font-semibold">Cafe Piala</div>

								</div> */}
                          <div class="flex flex-col mr-auto">
                            <span class="text-sm">Deliver to</span>
                            <div class="font-semibold">{address}</div>

                          </div>
                        </div>
                        <div class="flex items-center mb-4 px-5">
                          <div class="flex flex-col text-sm">
                            <span class="">Name</span>
                            <div class="font-semibold">{name}</div>

                          </div>
                          <div class="flex flex-col mx-auto text-sm">
                            <span class="">Phone</span>
                            <div class="font-semibold">{phone}</div>

                          </div>

                        </div>
                        <div class="border-b border-dashed border-b-2 my-5 pt-5">
                          <div class="absolute rounded-full w-5 h-5 bg-sky-500 -mt-2 -left-2"></div>
                          <div class="absolute rounded-full w-5 h-5 bg-sky-500 -mt-2 -right-2"></div>
                        </div>
                        <div class="flex items-center px-5 pt-3 text-sm">
                          <div class="flex flex-col">
                            <span class="">Bill</span>
                            <div class="font-semibold">{totalSum} Rs</div>

                          </div>
                          <div class="flex flex-col mx-auto">
                            <span class="">Delivery</span>
                            <div class="font-semibold">Free</div>

                          </div>
                          <div class="flex flex-col">
                            <span class="">Total</span>
                            <div class="font-semibold">{totalSum} Rs</div>

                          </div>
                        </div>
                        <div class="flex flex-col py-5  justify-center text-sm ">
                          <h6 class="font-bold text-center">Reciept</h6>

                          <div class="barcode h-14 w-0 inline-block mt-4 relative left-auto"></div>
                          <button onClick={openOverlay} class="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-blue-50 hover:bg-blue-600">Check out</button>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>








          {isOpen && (
            <div
              className="overlay"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 9998,
                boxShadow: '0 0 10px rgba(255, 255, 255, 0.8)',
                overflowY: 'auto',
                paddingTop: '250px',
                paddingBottom: '10px',
              }}
            >
              <div
                className="overlay-content"
                style={{
                  backgroundColor: 'black',
                  padding: '20px',
                  borderRadius: '4px',
                  maxWidth: '400px'
                }}
              >


                <div className="flex pb-3 pt-4 items-center">
                  {/* <div className="-ml-1 text-gray-600 dark:text-gray-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" />
                                            <line x1="4" y1="7" x2="20" y2="7" />
                                            <line x1="10" y1="11" x2="10" y2="17" />
                                            <line x1="14" y1="11" x2="14" y2="17" />
                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        </svg>
                                    </div> */}
                  <p className="text-lg text-gray-100 font-semibold pl-2">Confirm Order?</p>
                  <button
                    className="close-overlay"
                    onClick={closeOverlay}
                    style={{
                      marginLeft: 'auto',
                      cursor: 'pointer',
                      color: 'white'
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </button>
                </div>


                <div class="flex flex-col space-y-5">
                  <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder={`Order notes for us
e.g
Size, Color and other selection details`}
                    class="h-40 flex px-3 py-2 my-6 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                    required
                  ></textarea>

                  <div className="border border-sky-500 h-8 w-80 mb-4 md:mb-0 rounded-md flex items-center justify-center mx-3">
                    <div className="flex items-center">
                      <div className="h-1 w-1 rounded-full bg-sky-500 mr-1" />
                      <span className="text-sm text-sky-500 font-normal">Automatic Payment (Under Development)</span>
                    </div>
                  </div>

                  <div className="border border-white h-28 w-80 mb-4 md:mb-0 rounded-md flex items-center p-4 mx-3">
                    <div className="flex items-center">
                      <span className="text-xs text-white font-normal flex flex-col">
                        <span>Where to Pay?</span>
                        <span>Bank: Bank Al Habib</span>
                        <span>Account Title: ARSLAN QAYYUM</span>
                        <span>Account Number: 55830081000734014</span>
                        <span>IBAN: PK74BAHL55830081000734014</span>
                      </span>
                    </div>
                  </div>


                  <label class="flex space-x-1">

                    <span class="text-red-500">*</span>
                    <span class="font-medium text-gray-200 text-xs">Required</span>
                  </label>
                  <textarea
                    value={payment}
                    onChange={(e) => setPayment(e.target.value)}
                    placeholder={`payment details (Advance Payment)

e.g
Payment Method: Bank
Bank: UBL
Account No. 17368289489
Payment ref id: 3029039
amount transferred: 9000 PKR`}
                    class="h-60 flex px-3 pb-2 md:px-4 md:py-3 border-2 border-black rounded-lg font-medium placeholder:font-normal"
                    required
                  ></textarea>
                </div>






                <p className="mt-6 text-sm text-gray-300 pb-3 font-normal mb-2">Once the order is made, it can't be changed. Are you sure you want to order from the selected cart?</p>
                <button onClick={() => confirmOrder(userid)}
                  className="delete-account-btn focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs">Confirm Order</button>
              </div>
            </div>
          )}







        </div>
      </body>

      <Footer />

      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />


    </div>
  );
}

export default Cart;