import React, { useEffect } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const About = () => {

    useEffect(() => {



        window.scrollTo(0, 0); // Scroll to top when component mounts



    }, []);


    return (

        <div>
  <Header />
  <div className="py-16 lg:px-8">
    <div className="container m-auto px-6 text-gray-500 md:px-12 xl:px-0">
      <div className="mx-auto grid gap-6 md:w-3/4 lg:w-full lg:grid-cols-3">
        <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
          <div className="mb-12">
            <h3 className="text-2xl font-semibold text-purple-900">Order Queries</h3>
            <p className="mb-6 mt-2">Have questions about your order status, delivery times, or need assistance with order modifications?</p>
            <a href="mailto:qamsonsports@gmail.com" className="mt-8 w-fit bg-purple-500 justify-center text-center rounded-lg shadow px-10 py-3 flex items-center text-white">
              Contact
            </a>
          </div>
          <img src="https://tailus.io/sources/blocks/end-image/preview/images/graphic.svg" className="w-2/3 ml-auto -mb-12" alt="illustration" loading="lazy" width="900" height="600" />
        </div>
        <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
          <div className="mb-12">
            <h3 className="text-2xl font-semibold text-purple-900">Brand Queries</h3>
            <p className="mb-6 mt-2">Interested in learning more about our products, services, or have any general questions about Transknit?</p>
            <a href="mailto:qamsonsports@gmail.com" className="mt-8 w-fit bg-purple-500 justify-center text-center rounded-lg shadow px-10 py-3 flex items-center text-white">
              Contact
            </a>
          </div>
          <img src="https://tailus.io/sources/blocks/end-image/preview/images/ui-design.svg" className="w-2/3 ml-auto" alt="illustration" loading="lazy" width="900" height="600" />
        </div>
        <div className="bg-white rounded-2xl shadow-xl px-8 py-12 sm:px-12 lg:px-8">
          <div className="mb-12">
            <h3 className="text-2xl font-semibold text-purple-900">Complaints</h3>
            <p className="mb-6 mt-2">Need to report an issue, request a return, or make a complaint about a product or service you received?</p>
            <a href="mailto:qamsonsports@gmail.com" className="mt-8 w-fit bg-purple-500 justify-center text-center rounded-lg shadow px-10 py-3 flex items-center text-white">
              Contact
            </a>
          </div>
          <img src="https://tailus.io/sources/blocks/end-image/preview/images/ux-design.svg" className="w-2/3 ml-auto" alt="illustration" loading="lazy" width="900" height="600" />
        </div>
      </div>
    </div>
  </div>
  <Footer />
</div>

    );
}

export default About;