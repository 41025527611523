import React, { useEffect } from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

import brandLogo from '../images/qamson.png'

const About = () => {

    useEffect(() => {
       
        
    
        window.scrollTo(0, 0); // Scroll to top when component mounts

    
    
      }, []);


    return (
        <div class=" bg-white">
            <div>
                <Header />
                <div class="container m-auto px-6 text-gray-100 md:px-12 xl:px-6 lg:py-16 pb-16 2xl:px-16">
                    <div class=" md:space-y-0 lg:flex space-x-4 lg:items-center lg:gap-12">
                        <div class="lg:w-4/12">
                            <img src={brandLogo} alt="image" loading="lazy" width="" height="" />
                        </div>
                        <div class="lg:w-8/12">
                        <div>
                            <h2 class="text-2xl text-gray-950 font-bold md:text-4xl">Qamson Sports</h2>
                            <p class="mt-6 text-gray-800">At Qamson Sports, we are dedicated to fueling the passion for sports and fitness with our top-of-the-line equipment and apparel. Our mission is to empower athletes of all levels, from beginners to professionals, with the tools they need to excel and achieve their goals. We understand that sports are not just a pastime but a way of life, and we are committed to supporting that lifestyle with products that combine performance, durability, and innovation.</p>
                            <p class="mt-4 text-gray-800">Our extensive range of sports equipment is meticulously designed and tested to meet the highest standards of quality and reliability. Whether you're hitting the gym, the field, or the court, Qamson Sports has got you covered with gear that enhances your performance and keeps you safe. From cutting-edge footwear and apparel to state-of-the-art training equipment, every product in our lineup is crafted with precision and care.</p>
                            <p class="mt-4 text-gray-800">We believe that staying active and healthy should be accessible to everyone, which is why we offer a wide selection of products that cater to various sports and fitness levels. Our team of experts is always on the lookout for the latest trends and advancements in sports technology, ensuring that our customers have access to the best and most innovative products on the market.</p>
                            <p class="mt-4 text-gray-800">At Qamson Sports, customer satisfaction is our top priority. We take pride in providing exceptional service and support, helping you find the right products to match your needs and preferences. Join the Qamson Sports community today and take your game to the next level with gear that inspires confidence and drives performance.</p>
                        </div>

                       
                        </div>
                        
                    </div>
                </div>
            </div>
           
            <Footer />
        </div>
    );
}

export default About;