import React, { useState, useEffect } from "react";
import { FaShoppingBag } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { FaSignOutAlt } from 'react-icons/fa';
import brandLogo from '../images/qamson.png'

const Header = () => {

  const websitePrefix = 'www.qamsonsports.com';

  const [publicLogin, setPublicLogin] = useState('');
  const [name, setName] = useState('');


  const getFirstUppercaseLetter = (str) => {
    return str.charAt(0).toUpperCase();
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setPublicLogin(localStorage.getItem(`${websitePrefix}-publiclogin`));
    setName(localStorage.getItem(`${websitePrefix}-publicname`));

  }, []);






  const [isMenuOpen, setMenuOpen] = useState(false);



  const handleLogout = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeOverlay = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handlePublicLogout = () => {
    localStorage.setItem(`${websitePrefix}-publiclogin`, null);
    localStorage.setItem(`${websitePrefix}-publicid`, null);
    localStorage.setItem(`${websitePrefix}-publicname`, null);
    localStorage.setItem(`${websitePrefix}-publicemail`, null);
    localStorage.setItem(`${websitePrefix}-publicpassword`, null);
    localStorage.setItem(`${websitePrefix}-publicphone`, null);
    localStorage.setItem(`${websitePrefix}-publicaddress`, null);
    closeOverlay();
    setPublicLogin(null);
  };





  const [isOpen, setIsOpen] = useState(false);

  const handleToggleSidebar = () => {
    setIsOpen(!isOpen);
  };



  return (
    <header className="font-poppins">
      <nav class="bg-transparent bg-opacity-0 px-6 md:px-20 lg:px-6">
        <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <div className="flex flex-row items-center space-x-8">



            {/* sidebar */}
            <div className="block relative z-50 lg:hidden">

              <button className="flex items-center px-2 py-2 text-gray-900 border-teal-400 hover:text-red-500 lg:hidden" onClick={handleToggleSidebar}>
                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-6z" />
                </svg>
              </button>
              {isOpen && (
                <div className="absolute left-0 mt-2 w-52 p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <ul>
                      <li>
                        <Link to='/' class="text-sm block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
                      </li>
                      <li>
                        <Link to="/products" class="text-sm flex flex-row py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Products
                          <div class="ml-2 rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white block">New</div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" class="text-sm block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About us</Link>
                      </li>
                      <li>
                        <Link to="/contact" class="text-sm block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</Link>
                      </li>

                      <div className="block">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full rounded-md font-medium shadow-sm px-4 py-2 mt-1 text-sm hover:text-gray-700 text-white hover:bg-white bg-gray-950 duration-300 border hover:border-gray-300 border-transparent focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                          id="options-menu"
                          aria-haspopup="true"
                          aria-expanded="true"
                          //onClick={() => setIsOpen2(!isOpen2)}
                          onClick={() => window.location.href = "#/manufacturing"}
                        >
                          <span>
                            <p className="block">Manufacturing</p>
                          </span>

                          <svg
                            className="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M3 10a2 2 0 114 0 2 2 0 01-4 0zM10 10a2 2 0 114 0 2 2 0 01-4 0zM17 10a2 2 0 114 0 2 2 0 01-4 0z"
                            />
                          </svg>
                        </button>
                      </div>




                    </ul>

                  </div>
                </div>
              )}
            </div>
            {/* sidebar */}




            <Link to="/" class="flex items-center">
              <img src={brandLogo} class="mr-3 h-20 sm:h-24 p-3" alt="Flowbite Logo" />
            </Link>
          </div>

          <div class="flex items-center lg:order-2 border-l-2 pl-4">
            <Link to="/cart">
              <FaShoppingBag className="text-gray-950 w-6 h-6" />
            </Link>
            <div className="flex space-x-4">
              {publicLogin !== '1' ? (
                <div className="flex space-x-2">
                  <Link to="/login" className="duration-300 bg-neutral-950 text-xs text-white font-bold px-4 py-2 rounded-full ml-4">
                    Login
                  </Link>
                </div>

              ) : (

                <div className="container mx-auto flex items-center justify-between px-4">
                  <div className="flex flex-row items-center justify-between">

                    <Link to="/profile">
                      <div className="rounded-full bg-gray-950 text-white shadow w-8 h-8 flex items-center justify-center relative ml-4 font-bold">
                        {getFirstUppercaseLetter(name)}
                      </div>
                    </Link>




                    <button
                      className="flex items-center text-neutral-950 focus:outline-none ml-4 p-2 rounded"
                      onClick={handleLogout}
                    >
                      <div className="flex flex-row ">
                        <FaSignOutAlt className="text-gray-950" />
                      </div>
                    </button>



                    {isMenuOpen && (
                      <div
                        className="overlay"
                        style={{
                          position: 'fixed',
                          top: 110,
                          right: 40,
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'flex-end',
                          zIndex: 9998,
                          opacity: isMenuOpen ? 1 : 0,
                          transition: 'opacity 0.5s ease-in-out',
                        }}
                      >
                        <div
                          className="overlay-content"
                          style={{
                            backgroundColor: 'black',
                            padding: '20px',
                            borderRadius: '16px',
                            maxWidth: '400px'
                          }}
                        >
                          <div className="flex pb-3 items-center">
                            <div className="-ml-1 text-gray-200">
                              <FaSignOutAlt />
                            </div>
                            <p className="text-lg text-gray-100 font-semibold pl-2">Confirm logout</p>
                            <button
                              className="close-overlay"
                              onClick={closeOverlay}
                              style={{
                                marginLeft: 'auto',
                                cursor: 'pointer',
                                color: 'white'
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                              </svg>
                            </button>
                          </div>
                          <p className="text-sm text-gray-200 pb-3 font-normal mb-2">Do you really want to logout?</p>
                          <button
                            onClick={() => handlePublicLogout()}
                            className="delete-account-btn focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}


                  </div>
                </div>

              )}


            </div>


          </div>
          <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
            <ul class="flex flex-col items-center mt-4 font-normal lg:flex-row lg:space-x-8 lg:mt-0 text-sm">
              <li>
                <Link to="/" class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
              </li>
              <li>
                <Link to="/products" class="flex flex-row py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Products
                  <div class="ml-2 hidden rounded-full bg-sky-500 px-1.5 py-0.5 text-xs text-white sm:block">New</div>
                </Link>
              </li>
              <li>
                <Link to="/about" class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About us</Link>
              </li>

              <div className="block">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md font-medium shadow-sm px-4 py-2 mt-1 text-sm text-gray-700 hover:text-white bg-white hover:bg-gray-950 duration-300 border border-gray-300 hover:border-transparent focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                  id="options-menu"
                  aria-haspopup="true"
                  aria-expanded="true"
                  //onClick={() => setIsOpen2(!isOpen2)}
                  onClick={() => window.location.href = "#/manufacturing"}
                >
                  <span>
                    <p className="block">Manufacturing</p>
                  </span>

                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M3 10a2 2 0 114 0 2 2 0 01-4 0zM10 10a2 2 0 114 0 2 2 0 01-4 0zM17 10a2 2 0 114 0 2 2 0 01-4 0z"
                    />
                  </svg>
                </button>
              </div>

            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;